import React from 'react';
import type { WidgetProps } from '@wix/yoshi-flow-editor';
import CssVars from '../../PackagePicker/CssVars';
import type { ThankYouWidgetProps } from '../ThankYouWidgetController';
import ThankYouWidget from './ThankYouWidget';

export default function (props: WidgetProps<ThankYouWidgetProps>) {
  return (
    <>
      <ThankYouWidget {...props} />
      <CssVars id={props.host.id} style={props.host.style} />
    </>
  );
}
